import React, { useState } from "react";
import Footer from "../components/Footer/Footer";
import FullTextHTMLplaceholderSection from "../components/FullTextSection/FullTextHTMLplaceholderSection";
import Navbar from "../components/Navbar/Navbar";
import ScrollToTop from "../components/ScrollToTop";
import Sidebar from "../components/Sidebar/Sidebar";
import { useNavigate } from "react-router";

const Licenses = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();
  const navbarItems = [
    {
      onclick: function () {
        navigate("/");
      },
      title: "Back to Home",
    },
  ];
  const navSettings = {
    smooth: true,
    duration: 500,
    spy: true,
    exact: "true",
    offset: -80,
    signinHidden: true,
    navBarDark: true,
  };
  return (
    <>
      <ScrollToTop />
      <Navbar toggle={toggle} items={navbarItems} navSettings={navSettings} />
      <Sidebar
        isOpen={isOpen}
        items={navbarItems}
        navSettings={navSettings}
        toggle={toggle}
      />
      <FullTextHTMLplaceholderSection
        {...{
          id: "license",
          content: ImprintContent,
        }}
      />
      <Footer />
    </>
  );
};

const ImprintContent = (
  <div class="gs">
    <h2>Impressum</h2>
    <p Style={"margin-top: 10px; margin-bottom: 10px"}>
      <span>
        <span>
          <span>
            <span>Angaben gemäß § 5 TMG</span>
          </span>
        </span>
      </span>
    </p>

    <p Style={"margin-top: 10px; margin-bottom: 10px; text-align: start"}>
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>&nbsp;</span>
              </span>
            </span>
          </span>
        </span>
      </span>
    </p>

    <p Style={"margin-top: 10px; margin-bottom: 10px"}>
      <span>
        <span>
          <span>
            <span>
              OC Services GmbH
              <br />
              Innere Kanalstr. 15
              <br />
              50823 Köln
            </span>
          </span>
        </span>
      </span>
    </p>

    <p Style={"margin-bottom: 19px"}>
      <span>
        <span>
          <strong>
            <span>
              <span>Vertreten durch:</span>
            </span>
          </strong>
          <br />
          <span>
            <span>
              <span>
                Geschäftsführer: Carsten Schneider, Gereon Abendroth, Dr. Marc
                Störing
              </span>
            </span>
          </span>
        </span>
      </span>
    </p>

    <p Style={"margin-top: 10px; margin-bottom: 10px"}>
      <span>
        <span>
          <strong>
            <span>
              <span>Kontakt:</span>
            </span>
          </strong>
          <br />
          <span>
            <span>
              Telefon: +49 221 67057014
              <br />
              E-Mail:&nbsp;
              <a href="mailto:info@osborneclarke-services.com">
                <span>
                  <span>
                    <span>info@osborneclarke-services.com</span>
                  </span>
                </span>
              </a>
            </span>
          </span>
        </span>
      </span>
    </p>

    <p Style={"margin-top: 10px; margin-bottom: 10px"}>
      <span>
        <span>
          <strong>
            <span>
              <span>Registereintrag:</span>
            </span>
          </strong>
          <br />
          <span>
            <span>
              Eintragung im Registergericht: Amtsgericht Köln
              <br />
              Registernummer:
            </span>
          </span>
          <span>HRB 92393</span>
        </span>
      </span>
    </p>

    <p Style={"margin-top: 10px; margin-bottom: 16px; text-align: start"}>
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  <strong>
                    <span>
                      <span>Umsatzsteuer-ID:</span>
                    </span>
                  </strong>
                  <br />
                  <span>
                    <span>
                      Umsatzsteuer-Identifikationsnummer gemäß §27a
                      Umsatzsteuergesetz:
                    </span>
                  </span>
                  <span>DE315712225</span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    </p>

    <p Style={"margin-top: 10px; margin-bottom: 10px"}>
      <span>
        <span>
          <strong>
            <span>
              <span>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</span>
            </span>
          </strong>
          <br />
          <span>
            <span>
              Gereon Abendroth
              <br />
              c/o OC Services GmbH
              <br />
              Innere Kanalstr. 15
              <br />
              50823 Köln
            </span>
          </span>
        </span>
      </span>
    </p>
  </div>
);

export default Licenses;
