import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
import Navbar from "../components/Navbar/Navbar";
import Sidebar from "../components/Sidebar/Sidebar";
import HeroSection from "../components/HeroSection/Hero";
import InfoSection from "../components/InfoSection/InfoSection";
import Services from "../components/CardsSection/CardsSection";
import SurveySection from "../components/SurveySection/SurveySection";
import Footer from "../components/Footer/Footer";

import ocManBulb from "../images/ocManBulb.gif";
import ocPhone from "../images/ocPhone.gif";
import ocMartinSoppe from "../images/ocMartinSoppe.jpg";
import ocLouisaHartig from "../images/ocLouisaHartig.jpg";
import { Button } from "../components/ButtonElement";
import { surveyJson } from "./../surveyJson";
import FullText from "../components/FullTextSection/FullTextSection";

const Home = () => {
  let surveyData = {};
  const surveySectionOnChangeCallback = (data) => {
    surveyData = data;
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar
        toggle={toggle}
        isOpen={isOpen}
        items={navbarItems}
        navSettings={navSettings}
      />
      <Navbar toggle={toggle} items={navbarItems} navSettings={navSettings} />
      <HeroSection {...heroSectionContent} />

      <FullText {...fullTextSectionContent[0]} />
      <SurveySection
        surveyJson={surveyJson}
        surveySectionOnChangeCallback={surveySectionOnChangeCallback}
      />
      <InfoSection {...infoSectionContents[0]} />
      <Services {...cardSectionContent} />
      <Footer />
      {
        <CookieConsent
          style={cookieConsentContents.style}
          buttonStyle={cookieConsentContents.buttonStyle}
          expires={cookieConsentContents.expires}
          enableDeclineButton={cookieConsentContents.enableDeclineButton}
          overlay={cookieConsentContents.overlay}
          setDeclineCookie={cookieConsentContents.setDeclineCookie}
          contentStyle={cookieConsentContents.contentStyle}
          onDecline={cookieConsentContents.onDecline}
        >
          <p>{cookieConsentContents.text}</p>
          <br></br>
          <p id="onetrust-policy-text">
            <a
              href={cookieConsentContents.link}
              aria-label={cookieConsentContents.linkLabel}
            >
              {cookieConsentContents.label}
            </a>
          </p>
        </CookieConsent>
      }
    </>
  );
};

export default Home;

// page content objects
export const heroSectionContent = {
  header: "Urheberauskunftspflicht in a box",
  description:
    "Besteht für Ihr Unternehmen eine jährliche Auskunftspflicht gegenüber Urhebern über den Umfang der Werknutzung und die Erträge und Vorteile, die Ihr Unternehmen daraus zieht?",
};
const cookieConsentContents = {
  style: { background: "#2B373B" },
  buttonStyle: { color: "#4e503b", fontSize: "13px" },
  expires: 150,
  enableDeclineButton: "true",
  overlay: "true",
  setDeclineCookie: "false",
  contentStyle: { fontSize: "large" },
  onDecline: () => {
    window.location.href = "https://www.osborneclarke.com";
  },
  label: "Cookie-Richtlinie anzeigen",
  link: "https://www.osborneclarke.com/cookie-policy",
  linkLabel: "Cookie-Richtlinie anzeigen",
  text: " 🍪 Einige Cookies sind unerlässlich, damit unsere Website wie beabsichtigt funktioniert. Andere sind nicht unbedingt erforderlich, helfen aber dabei, unsere Website und Ihre Erfahrung zu verbessern. Sie können auswählen, welche optionalen Cookies Sie zulassen möchten, indem Sie die Schaltfläche „Cookie-Einstellungen“ unten verwenden. Das Blockieren einiger Arten von Cookies kann jedoch Ihre Erfahrung mit der Website und den Diensten, die wir anbieten können, beeinträchtigen. Durch die Verwendung dieses Tools wird ein Cookie auf Ihrem Gerät gesetzt, um Ihre Präferenzen zu speichern.",
};
const cardSectionContent = {
  heading: "Ihre Ansprechpartner",
  cards: [
    {
      heading: (
        <a
          class="contactName"
          target="blank"
          href="https://www.osborneclarke.com/lawyers/martin-soppe"
        >
          Dr. Martin Soppe
        </a>
      ),
      text: "Rechtsanwalt/Partner",
      link: "https://www.osborneclarke.com/",
      email: (
        <a class="contactEmail" href="mailto:martin.soppe@osborneclarke.com">
          E-Mail an Martin senden
        </a>
      ),
      image: ocMartinSoppe,
    },
    {
      heading: (
        <a
          class="contactName"
          target="blank"
          href="https://www.osborneclarke.com/lawyers/louisa-hartig"
        >
          Louisa Hartig
        </a>
      ),
      text: "Rechtsanwältin/Associate",
      email: (
        <a class="contactEmail" href="mailto:louisa.hartig@osborneclarke.com">
          E-Mail an Louisa senden
        </a>
      ),
      image: ocLouisaHartig,
    },
  ],
};
const navbarItems = [
  {
    to: "about",
    title: "Urheberrechtsgesetz",
  },
  {
    to: "surveys",
    title: "Tool",
  },
  {
    to: "discover",
    title: "Kontakt",
  },
  {
    to: "services",
    title: "Ansprechpartner",
  },
  {
    to: "signup",
    title: "",
  },
];
const navSettings = {
  smooth: true,
  duration: 500,
  spy: true,
  exact: "true",
  offset: -80,
  signinHidden: true,
};

const fullTextSectionContent = [
  {
    id: "about",
    lightBg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: "§ 32d Urheberrechtsgesetz (UrhG)",
    headline: "Status quo",
    content: (
      <>
        <p>
          Seit dem Juni 2021 ist in Deutschland ein neuer § 32d
          Urheberrechtsgesetz (UrhG) in Kraft getreten. Nach dieser Vorschrift
          müssen Unternehmen nunmehr ihren Urhebern anlasslos, also ohne
          gesonderte Aufforderung, jährlich Informationen über die Nutzung ihrer
          Werke erteilen. Tun sie dies nicht, besteht neben einer Klage eines
          einzelnen Urhebers auch die Möglichkeit, dass Urhebervereinigungen
          eine Verbandsklage gegen ein Unternehmen richten, um die Auskunft für
          alle Auskunftsberechtigten zu erzwingen. Für die Praxis hat die neue
          jährliche Auskunftspflicht weitreichende Folgen und kann zu immensem
          Verwaltungsaufwand führen. Dies betrifft alle Unternehmen, die
          urheberrechtlich geschützte Werke nutzen. Hierzu gehören
          beispielsweise jegliche Publisher im weitesten Sinne (wie
          Medienunternehmen), Games-Hersteller, Werbeagenturen und
          (Web-)Designstudios, aber ggf. auch Architekturbüros ebenso wie
          Organisationen, die speziell direkt für sie erstellte PR- oder
          Lehrmaterialien nutzen.
        </p>
        <br />
        <h1 Style="text-align:center; font-weight: 600;  font-size: 42px;  @media screen and (max-width: 480px) { font-size: 32px;  }">
          Unser Tool
        </h1>
        <br />

        <p>
          Mit diesem Tool können Sie einen Überblick darüber erhalten, ob Ihr
          Unternehmen ebenfalls von der neuen Auskunftspflicht erfasst wird. Je
          nachdem, welche urheberrechtlich geschützten Werke Sie nutzen und von
          wem Sie sich diese beschafft haben, führt Sie das Tool durch die
          Vorschrift des § 32d UrhG. Es werden viele Beispiele genannt und auch
          etwaige in Betracht kommende Ausnahmen berücksichtigt. Mit dem Tool
          lassen sich verschiedene Konstellationen durchspielen. Das verschafft
          Ihnen einen Durchblick durch das „Dickicht“ der Ausnahmen und
          Rückausnahmen des § 32d UrhG.
        </p>
      </>
    ),
    // "",
    buttonLabel: "nach oben",
    buttonTo: "home",
    imgStart: false,
    //img: ocRecruitment2,
    //alt: "homeLogo",
    dark: false,
    primary: false,
    darkText: true,
  },
];
const infoSectionContents = [
  {
    id: "discover",
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "Haben Sie weitere Fragen?",
    headline: "Nehmen Sie Kontakt auf!",
    description: "Wir stehen Ihnen gerne jederzeit zur Verfügung",
    buttonLabel: "Zu den Kontakten",
    buttonTo: "services",
    imgStart: true,
    img: ocManBulb,
    alt: "ocManBulb",
    dark: false,
    primary: false,
    darkText: true,
  },
];
