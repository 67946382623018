//import { useCallback } from "react";
import "survey-core/defaultV2.min.css";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "./style.css";
import { marked } from "marked";

import styled from "styled-components";

StylesManager.applyTheme("defaultV2");

export function SurveyApp({ surveyJson, surveyOnChangeCallback }) {
  const survey = new Model(surveyJson);
  survey.focusFirstQuestionAutomatic = false;
  survey.onTextMarkdown.add(function (survey, options) {
    //convert the markdown text to html
    let html = marked.parse(options.text);
    //remove pssible root p tag
    if (html.startsWith("<p>")) {
      html = html.substring(3, html.length - 5);
    }
    options.html = html;
  });
  survey.onValueChanged.add(updateSurveyData);
  survey.onComplete.add(onCompleteFunction);
  /*   const onCompleteFunction = useCallback((sender) => {
    const results = JSON.stringify(sender.data);
    alert(results);
    survey.clear();
    debugger;
    survey.scrollIntoView();
  }, []); */
  function onCompleteFunction(sender) {
    // const results = JSON.stringify(survey.data);
    // alert(results);
    survey.clear();
    document.getElementById("surveys").scrollIntoView();
    window.scrollBy(0, -64);
  }
  function updateSurveyData() {
    surveyOnChangeCallback(survey.data);
  }

  return <Survey id="surveys" model={survey} />;
}

//export default SurveyApp;

export const SurveyContainer = styled.div`
  /* height: 800px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #001822;
  color: #ffffff;
  font-family: "Jost", sans-serif;

  @media screen and (max-width: 768px) {
    /*  height: 1100px; */
  }

  @media screen and (max-width: 480px) {
    /* height: 1300px; */
  }
`;

export const SurveyH1 = styled.h1`
  font-size: 2.5rem;
  color: #ffffff;
  margin-top: 64px;

  @media screen and (max-width: 480px) {
    padding: 15px;
    font-size: 1.7rem;
  }
`;

export const SurveyP = styled.p`
  font-size: 1rem;
  text-align: justify;
  color: #ffffff;
  max-width: calc(84 * var(--base-unit, 8px));
  margin-left: auto;
  margin-right: auto;
`;
