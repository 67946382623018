// Initialize a connection to Application Insights

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory({ basename: "" });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    connectionString:
      "InstrumentationKey=a855cb45-0035-4d0f-8e96-94ee07cdbfe9;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
    // since router history isn't exposed here (react-router v6), Application Insights configuration enableAutoRouteTracking is used to auto-track router changes
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
    disableCookiesUsage: true,
    isCookieUseDisabled: true,
    isStorageUseDisabled: true,
    enableSessionStorageBuffer: true,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

export { reactPlugin, appInsights };
