import React from "react";
import { Button } from "../ButtonElement";
import {
  FullTextContainer,
  FullTextH1,
  FullTextP,
  TopLine,
  Subtitle,
} from "./FullTextElements";

const FullText = ({
  id,
  topLine,
  subtitle,
  to,
  headline,
  content,
  buttonLabel,
  buttonTo,
  primary,
  dark,
  dark2,
}) => {
  return (
    <FullTextContainer id={id}>
      <TopLine>{topLine}</TopLine>
      <FullTextH1>{headline}</FullTextH1>
      <Subtitle>{subtitle}</Subtitle>

      <FullTextP>{content}</FullTextP>
      <Button
        to={buttonTo}
        smooth={true}
        duration={500}
        spy={true}
        exact="true"
        offset={-80}
        primary={primary ? 1 : 0}
        dark={dark ? 1 : 0}
        dark2={dark2 ? 1 : 0}
      >
        {buttonLabel}
      </Button>
    </FullTextContainer>
  );
};

export default FullText;
