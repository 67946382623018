import React from "react";
import { animateScroll as scroll } from "react-scroll";
import {
  /*  FaFacebook, FaInstagram,  FaTwitter,*/ FaYoutube,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  /*  FooterLinkTitle,  */
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";

const Footer = () => {
  //function that scrolls to top of page
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer id="footer">
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              {/* <FooterLinkTitle>Footer Col 1</FooterLinkTitle> */}
              <FooterLink to="/">Home</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              {/* <FooterLinkTitle>Footer Col 2</FooterLinkTitle> */}
              <FooterLink to="/imprint">Impressum</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              {/*  <FooterLinkTitle>Footer Col 3</FooterLinkTitle> */}
              <FooterLink
                onClick={() => {
                  window.location =
                    "https://www.osborneclarke.com/website-privacy";
                }}
              >
                Datenschutz
              </FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              {/*  <FooterLinkTitle>Footer Col 4</FooterLinkTitle> */}
              <FooterLink to="/licenses">Lizenzen</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              ⌂
            </SocialLogo>
            <WebsiteRights>
              OC Services GmbH © {new Date().getFullYear()} All rights reserved
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink href="/" target="_blank" aria-label="Facebook">
                {/*       <FaFacebook />*/}
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="Instagram">
                {/*  <FaInstagram /> */}
              </SocialIconLink>
              <SocialIconLink
                href="https://twitter.com/osborneclarke?lang=en-gb"
                target="_blank"
                aria-label="Twitter"
              >
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.youtube.com/@osborneclarkegermany"
                target="_blank"
                aria-label="YouTube"
              >
                <FaYoutube />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.linkedin.com/company/osborne-clarke-germany/"
                target="_blank"
                aria-label="LinkedIn"
              >
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
