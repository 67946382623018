import React from "react";
import {
  SurveyApp,
  SurveyContainer,
  SurveyH1,
  SurveyP,
} from "./SurveyElements";

const SurveySection = ({ surveyJson, surveySectionOnChangeCallback }) => {
  const surveyOnChangeCallback = (data) => {
    surveySectionOnChangeCallback(data);
  };

  return (
    <SurveyContainer id="surveys">
      <SurveyH1>Urheberauskunftspflicht in a box</SurveyH1>
      <SurveyP></SurveyP>
      <SurveyApp
        surveyJson={surveyJson}
        surveyOnChangeCallback={surveyOnChangeCallback}
      ></SurveyApp>
    </SurveyContainer>
  );
};

export default SurveySection;
