export const surveyJson = {
  logoPosition: "right",
  pages: [
    {
      name: "homepage",
      elements: [
        {
          type: "html",
          name: "introduction",
          title: "",
          html: "",
        },

        {
          type: "checkbox",
          name: "disclaimer",
          title: "Disclaimer",
          description:
            "Das Tool Urheberauskunftspflicht („Tool“) wurde von der OC Solutions (OC Services GmbH) zu Informationszwecken erstellt. Es stellt keine individuelle rechtliche oder steuerliche Auskunft dar und ist nicht geeignet, eine individuelle Beratung durch fachkundige Personen zu ersetzen. User sollten nicht allein auf der Grundlage der Informationen des Tools handeln. Die Informationen stellen einen unverbindlichen kursorischen Überblick über die Rechtslage am 25. Oktober 2022 anhand der vom User gewählten Optionen dar und dürfen ohne die vorherige schriftliche Zustimmung der OC Services GmbH von niemand anderem außer dem User herangezogen, zitiert oder anderweitig verwendet werden. Es besteht keine Verpflichtung zu einer Beibehaltung oder Aktualisierung des Tools.",
          isRequired: true,
          requiredErrorText:
            "Bitte akzeptieren Sie den Disclaimer, um fortzufahren.",
          choices: [
            {
              value: "verstanden",
              text: "Verstanden <br><br><hr><br><div style='font-size: 10pt, font-weight: bold'>Hinweis:</div><br><div style='font-size: 8pt'>Sofern in dem Tool die grammatikalisch männliche Form verwendet wird, dient dies nur der besseren Lesbarkeit und spiegelt den Gesetzeswortlaut des Urheberrechtsgesetzes wider. Selbstverständlich werden alle Geschlechter mitumfasst.</div>",
            },
          ],
        },
        // {
        //   type: "expression",
        //   name: "hintMaleFemaleExpr",
        //   title: "<div style='font-size: 8pt'>Hinweis:</div>",
        //   description:
        //     "<div style='font-size: 8pt'>Sofern in dem Tool die grammatikalisch männliche Form verwendet wird, dient dies nur der besseren Lesbarkeit und spiegelt den Gesetzeswortlaut des Urheberrechtsgesetzes wider. Selbstverständlich werden alle Geschlechter mitumfasst.</div>",
        // },
      ],
      title: "",
    },
    {
      name: "general",
      elements: [
        {
          type: "radiogroup",
          name: "workOrigin",
          title:
            "Von wem stammen die urheberrechtlichen „Werke“, also die Inhalte, die Ihr Unternehmen nutzt?",
          isRequired: true,
          requiredErrorText: "Bitte wählen Sie eine Antwort aus.",
          choices: [
            {
              value: "agency",
              text: "Von einer Agentur",
            },
            {
              value: "employees",
              text: "Von unseren Arbeitnehmern",
            },
            {
              value: "freelancers",
              text: "Von freien Mitarbeitern oder sonstigen nicht bei uns im Unternehmen beschäftigten Urhebern",
            },
            {
              value: "trainees",
              text: "Von unentgeltlich beschäftigten Praktikanten",
            },
          ],
        },
        {
          type: "panel",
          name: "panelAgency",
          elements: [
            {
              type: "html",
              name: "noteAgencyText",
              visibleIf: "{workOrigin} = 'agency'",
              title: "Hinweis!",
              description:
                "Wenn Ihr Unternehmen die Werke von einer Agentur eingekauft hat, besteht von vornherein nur eine Auskunftspflicht für den Fall, dass die Agentur auf eine Anfrage des Urhebers innerhalb von drei Monaten nicht reagiert hat oder die durch die Agentur erteilte Auskunft unzureichend ist. Ihr Unternehmen muss die Auskunft auch nur erteilen, wenn der Urheber das ausdrücklich verlangt.",
              html: "<br><br><h5>Wichtiger Hinweis</h5><br><b>Wenn Ihr Unternehmen die Werke von einer Agentur eingekauft hat, besteht von vornherein nur eine Auskunftspflicht für den Fall, dass die <u>Agentur</u> auf eine Anfrage des Urhebers innerhalb von drei Monaten <u>nicht reagiert</u> hat oder die durch die <u>Agentur</u> erteilte <u>Auskunft unzureichend</u> ist. Ihr Unternehmen muss die Auskunft auch nur erteilen, wenn der Urheber das <u>ausdrücklich verlangt</u>.</b>",
            },
          ],
          visibleIf: "{workOrigin} = 'agency'",
        },
      ],
      title: "",
    },
    {
      name: "typeOfWorkPanel",
      elements: [
        {
          type: "panel",
          name: "panelTarifvertragYN",
          elements: [
            {
              type: "radiogroup",
              name: "tarifvertragYN",
              visibleIf: "{workOrigin} = 'employees'",
              title:
                "Besteht in Ihrem Unternehmen ein Tarifvertrag, der vorsieht, dass Sie Ihren Arbeitnehmern jährlich und anlasslos Auskunft über die Nutzung von urheberrechtlichen Werken erteilen?",
              choices: [
                {
                  value: "true",
                  text: "Ja",
                },
                {
                  value: "false",
                  text: "Nein",
                },
              ],
            },
            {
              type: "html",
              name: "tarifvertragYNHint",
              html: "<hr><b>Hinweis: Das ist derzeit erst selten der Fall.</b><hr>",
            },
          ],
          visibleIf: "{workOrigin} = 'employees'",
        },
        {
          type: "panel",
          name: "panelMutualRemunerationAnnualInformationYN",
          elements: [
            {
              type: "radiogroup",
              name: "mutualRemunerationAnnualInformationYN",
              visibleIf: "{workOrigin} = 'freelancers'",
              title:
                "Ist Ihr Unternehmen gegenüber dem jeweiligen Urheber an eine sog. „Gemeinsame Vergütungsregel“ gebunden, die bereits eine jährliche und anlasslose Auskunftspflicht zum Inhalt hat?",
              choices: [
                {
                  value: "true",
                  text: "Ja",
                },
                {
                  value: "false",
                  text: "Nein",
                },
              ],
            },
            {
              type: "html",
              name: "mutualRemunerationAnnualInformationYNHint",
              html: "<hr><b>Hinweis: Das ist derzeit erst selten der Fall.</b><hr>",
            },
          ],
          visibleIf: "{workOrigin} = 'freelancers'",
        },
        {
          type: "panel",
          name: "panelMutualRemunerationTransparencyYN",
          elements: [
            {
              type: "radiogroup",
              name: "mutualRemunerationTransparencyYN",
              visibleIf: "{workOrigin} = 'agency'",
              title:
                "Ist Ihr Unternehmen gegenüber dem jeweiligen Urheber an eine sog. „Gemeinsame Vergütungsregel“ gebunden, die bereits eine vergleichbare Transparenzpflicht beinhaltet?",
              choices: [
                {
                  value: "true",
                  text: "Ja",
                },
                {
                  value: "false",
                  text: "Nein",
                },
              ],
            },
            {
              type: "html",
              name: "mutualRemunerationTransparencyYNHint",
              html: "<hr><b>Hinweis: Das ist derzeit erst selten der Fall.</b><hr>",
            },
          ],
          visibleIf: "{workOrigin} = 'agency'",
        },
        {
          type: "radiogroup",
          name: "subLicensingYN",
          visibleIf: "{mutualRemunerationTransparencyYN} = false",
          minWidth: "200px",
          title:
            "Nimmt Ihr Unternehmen wirtschaftlich wesentliche Nutzungen bei den jeweiligen Werken vor (z. B. Weiterlizenzierungen des betroffenen Werkes)?",
          choices: [
            {
              value: "yes",
              text: "Ja",
            },
            {
              value: "no",
              text: "Nein",
            },
            {
              value: "notSure",
              text: "Weiß ich nicht, da bin ich mir unsicher",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "purchasedWorksNowBestsellerYN",
          visibleIf: "{subLicensingYN} = 'no'",
          title:
            "Haben sich Werke, die Ihr Unternehmen von einer Agentur eingekauft hat, zu Bestsellern entwickelt (vgl. § 32a UrhG)?",
          choices: [
            {
              value: "true",
              text: "Ja",
            },
            {
              value: "false",
              text: "Nein",
            },
          ],
        },
        {
          type: "panel",
          name: "panelpurchasedWorksNowBestsellerExample",
          elements: [
            {
              type: "html",
              name: "purchasedWorksNowBestsellerExample",
              visibleIf: "{subLicensingYN} = 'no'",
              html: "<br><b>Beispiel:<br><br></b>Ein bei einer Agentur eingekauftes Bild oder ein Text erweist sich im Nachhinein überraschend in Ihrem Unternehmen als Bestseller, sodass die ursprünglich vereinbarte Vergütung der Agentur an den Urheber nicht mehr angemessen ist.",
            },
          ],
          visibleIf: "{subLicensingYN} = 'no'",
        },
        {
          type: "panel",
          name: "panelTypeOfWork+Examples",
          elements: [
            {
              type: "radiogroup",
              name: "typeOfWork",
              visibleIf:
                "{tarifvertragYN} = false and {workOrigin} = 'employees'",
              title:
                "Um was für eine Tätigkeit handelt es sich, bei der der Arbeitnehmer das Werk erstellt hat?",
              description:
                "Für weitere Details wählen Sie bitte eine der Antwortmöglichkeiten aus.",
              choices: [
                {
                  value: "software",
                  text: "Um das Erstellen von Software (Computerprogramme, Anwendungen)",
                },
                {
                  value: "relevantWorks",
                  text: "Um eine Tätigkeit, bei der urheberrechtlich relevante Werke entstehen",
                },
                {
                  value: "iirrelevantWorks",
                  text: "Um eine Tätigkeit, die nur redigierend oder lektorierend ist oder bei der keine urheberrechtlich relevanten Werke entstehen",
                },
                {
                  value: "copyrightedWorksByAccident",
                  text: "Um eine Tätigkeit, bei deren Ausübung gewissermaßen nur „zufällig“ urheberrechtlich relevante Werke entstehen",
                },
                {
                  value: "notSure",
                  text: "Weiß ich nicht, da bin ich mir unsicher",
                },
              ],
            },
          ],
        },
        {
          type: "panel",
          name: "panelExamplestypeOfWork",
          elements: [
            {
              type: "html",
              name: "illustratorWorks",
              visibleIf: "{typeOfWork} = 'relevantWorks'",
              title:
                "Beispiele: Tätigkeit in der Redaktion eines Verlages, als Illustrator, als Fotograf, als Texter für Webseiten oder Blogs ",
              html: "<br><b>Beispiele:<br><br></b><ul><li>Tätigkeit in der Redaktion eines Verlages<br><li>Tätigkeit als Illustrator<br><li>Tätigkeit als Fotograf<br><li>Tätigkeit als Texter für Webseiten oder Blogs",
            },
            {
              type: "html",
              name: "contractDraftWorks",
              visibleIf: "{typeOfWork} = 'copyrightedWorksByAccident'",
              title:
                "Beispiele: Die Justitiarin in Ihrem Unternehmen erstellt einen aufwändigen Vertragsentwurf oder die Mitarbeiterin im Controlling entwirft eine umfangreiche Unternehmenspräsentation. ",
              html: "<br><b>Beispiele:<br><br></b>Die Justitiarin in Ihrem Unternehmen erstellt einen aufwändigen Vertragsentwurf oder die Mitarbeiterin im Controlling entwirft eine umfangreiche Unternehmenspräsentation.",
            },
            {
              type: "html",
              name: "archiveResearchWorks",
              visibleIf: "{typeOfWork} = 'iirrelevantWorks'",
              title:
                "Beispiele: Sammlung, Sichtung, Auswahl oder Bearbeitung von Wort- oder Bildmaterial, reine Archiv- und Dokumentationstätigkeiten, Recherchetätigkeiten, Suchmaschinenoptimierung von Webseitentexten",
              html: "<br><b>Beispiele:<br><br></b><ul><li>Sammlung, Sichtung, Auswahl oder Bearbeitung von Wort- oder Bildmaterial<br><li>reine Archiv- und Dokumentationstätigkeiten<br><li>Recherchetätigkeiten<br><li>Suchmaschinenoptimierung von Webseitentexten",
            },
          ],
          visibleIf: "{tarifvertragYN} = false and {workOrigin} = 'employees'",
        },
        {
          type: "panel",
          name: "panelWorksUsedFromCreator",
          elements: [
            {
              type: "radiogroup",
              name: "worksUsedFromCreator",
              visibleIf:
                "{mutualRemunerationAnnualInformationYN} = false and {workOrigin} = 'freelancers'",
              title:
                "Um was für Tätigkeiten geht es, die der Urheber für Ihr Unternehmen ausübt?",
              description:
                "Für weitere Details wählen Sie bitte eine der Antwortmöglichkeiten aus.",
              choices: [
                {
                  value: "software",
                  text: "Um das Erstellen von Software (Computerprogramme, Anwendungen)",
                },
                {
                  value: "iirrelevantWorks",
                  text: "Um eine Tätigkeit, die nur redigierend oder lektorierend ist oder bei der keine urheberrechtlich relevanten Werke entstehen",
                },
                {
                  value: "creationFollowingWorks",
                  text: "Um eine Tätigkeit, bei der urheberrechtlich relevante Werke entstehen",
                },
                {
                  value: "notSure",
                  text: "Weiß ich nicht, da bin ich mir unsicher",
                },
              ],
            },
          ],
          visibleIf: "{mutualRemunerationAnnualInformationYN} = false",
        },
        {
          type: "panel",
          name: "panelWorksUsedFromCreatorExamples",
          elements: [
            {
              type: "html",
              name: "archiveResearchWorks2",
              visibleIf: "{worksUsedFromCreator} = 'iirrelevantWorks'",
              minWidth: "100px",
              html: "<br><b>Beispiele:<br><br></b><ul><li>Sammlung, Sichtung, Auswahl oder Bearbeitung von Wort- oder Bildmaterial<br><li>reine Archiv- und Dokumentationstätigkeiten<br><li>Recherchetätigkeiten<br><li>Suchmaschinenoptimierung von Webseitentexten",
            },
            {
              type: "html",
              name: "textsMinimumCreativity",
              visibleIf: "{worksUsedFromCreator} = 'creationFollowingWorks'",
              minWidth: "100px",
              html: "<br><b>Beispiele:<br><br></b><ul><li>Texte (mit einem Mindestmaß an „Kreativität“, z. B. nicht bloße Gebrauchsanweisungen)<br><li>Grafiken, Bilder, Illustrationen<br><li>Videos, Ton-/Musikaufnahmen<br><li>Sonstiges mit einem Mindestmaß an „Schöpfungshöhe“",
            },
          ],
          visibleIf: "{mutualRemunerationAnnualInformationYN} = false",
        },
        {
          type: "radiogroup",
          name: "bestsellerYN",
          visibleIf:
            "{typeOfWork} = 'relevantWorks' or {typeOfWork} = 'notSure'",
          title:
            "Handelt es sich um einen sogenannten „Bestsellerfall“ (vgl. § 32a UrhG) oder einen Fall der Nachvergütung von Werken, bei denen die Art der Nutzung innovativ ist und erst später bekannt geworden ist (vgl. § 32c UrhG)?",
          choices: [
            {
              value: "true",
              text: "Ja",
            },
            {
              value: "false",
              text: "Nein",
            },
          ],
        },
        {
          type: "panel",
          name: "panelbestsellerYNExample",
          elements: [
            {
              type: "html",
              name: "bestsellerYNExample",
              visibleIf:
                "{typeOfWork} = 'relevantWorks' or {typeOfWork} = 'notSure'",
              html: "<br><b>Beispiel für einen „Bestsellerfall“:</b><br><br>Eine bestimmte Fotografie oder ein Text erweist sich überraschend im Nachhinein als Bestseller, sodass die ursprünglich vereinbarte Vergütung nicht mehr angemessen ist.<b><br><br>Beispiel für eine Nachvergütung bei einer erst nach dem Vertragsschluss mit dem Urheber bekannt gewordenen Nutzungsart:<br><br></b>Ein von dem Urheber entworfener Unternehmensjingle wurde im „rein analogen Zeitalter“ erstellt und wird nun gestreamt.",
            },
          ],
          visibleIf:
            "{typeOfWork} = 'relevantWorks' or {typeOfWork} = 'notSure'",
        },
        {
          type: "panel",
          name: "panelDefiningWorksYN",
          elements: [
            {
              type: "radiogroup",
              name: "definingWorksYN",
              visibleIf:
                "{worksUsedFromCreator} = 'creationFollowingWorks' or {worksUsedFromCreator} = 'notSure'",
              title:
                "Sind die Werke prägend für das Gesamtwerk oder das von Ihrem Unternehmen angebotene Produkt oder die von Ihnen angebotene Dienstleistung?",
              description:
                "Für weitere Details wählen Sie bitte eine der Antwortmöglichkeiten aus.",
              choices: [
                {
                  value: "veryDefining",
                  text: "Ja, denn die jeweiligen Werke prägen das Gesamtwerk und/oder gehören zum typischen Inhalt unseres Produktes und/oder leisten im Vergleich zu unserer Gesamtwertschöpfung einen nicht nur geringen Beitrag.",
                },
                {
                  value: "No",
                  text: "Nein",
                },
                {
                  value: "notSure",
                  text: "Weiß ich nicht, da bin ich mir unsicher",
                },
              ],
            },
          ],
          visibleIf:
            "{worksUsedFromCreator} = 'creationFollowingWorks' or {worksUsedFromCreator} = 'notSure'",
        },
        {
          type: "panel",
          name: "panelDefiningWorksYNExamples",
          elements: [
            {
              type: "html",
              name: "definingWorksYNExample",
              visibleIf: "{definingWorksYN} = 'veryDefining'",
              html: "<br><b>Beispiele (aus dem Verlagswesen):<br><br></b>Es handelt sich um eine Titelgeschichte, einen Aufmacher oder ein Titelbild in einer Zeitung/Zeitschrift/Webseite/Blog etc.<b><br><br>Gegenbeispiel (ebenfalls aus dem Verlagswesen): <br><br></b>Es handelt sich um einen „normalen“ Text, Artikel oder eine Abbildung in einer Zeitung/Zeitschrift/Webseite/Blog etc.",
            },
          ],
          visibleIf:
            "{worksUsedFromCreator} = 'creationFollowingWorks' or {worksUsedFromCreator} = 'notSure'",
        },
        {
          type: "panel",
          name: "panelRelationInfoRevenue",
          elements: [
            {
              type: "radiogroup",
              name: "relationInfoRevenue",
              visibleIf:
                "{subLicensingYN} = 'yes' or {definingWorksYN} = 'veryDefining' or {purchasedWorksNowBestsellerYN} = true",
              title:
                "Stünde der Aufwand für die zu erteilende Auskunft außer Verhältnis zu den Einnahmen, die Ihr Unternehmen aus der Nutzung des jeweiligen Werkes erzielt?",
              description:
                "Für weitere Details wählen Sie bitte eine der Antwortmöglichkeiten aus.",
              choices: [
                {
                  value: "yNoLateRemunerationClaim",
                  text: "Ja, da ein Nachvergütungsanspruch in dem betreffenden Fall von vornherein nicht in Betracht kommt.",
                },
                {
                  value: "yManyCreatorsInvolved",
                  text: "Ja, weil an dem betroffenen „Gesamtwerk“ eine Vielzahl von Urhebern beteiligt ist und sich der Aufwand für unser Unternehmen stark kumulieren würde.",
                },
                {
                  value: "yCreatorsReceiveInfoRegularly",
                  text: "Ja, weil die betroffenen Urheber ohnehin schon regelmäßig und unaufgefordert von uns oder von Dritten eine vergleichbare Auskunft bekommen.",
                },
                {
                  value: "nothingApplies",
                  text: "Nichts von alledem trifft zu.",
                },
                {
                  value: "notSure",
                  text: "Weiß ich nicht, da bin ich mir unsicher.",
                },
              ],
            },
          ],
          visibleIf:
            "{subLicensingYN} = 'yes' or {definingWorksYN} = 'veryDefining' or {purchasedWorksNowBestsellerYN} = true",
        },
        {
          type: "panel",
          name: "panelRelationInfoRevenueExamples",
          elements: [
            {
              type: "html",
              name: "yNoLateRemunerationClaimExample",
              visibleIf: "{relationInfoRevenue} = 'yNoLateRemunerationClaim'",
              html: "<br><b>Beispiele: <br><br></b>\n\n<ul><li>Die Vergütung des Urhebers ist branchenüblich und auch nach den Grundsätzen der Rechtsprechung nicht unangemessen niedrig (vgl. § 32 Abs. 1 UrhG). <br><li>\nEs ist keine sogenannte „Bestsellersituation“ eingetreten (vgl. § 32a UrhG). <br><li>\nIhr Unternehmen nutzt das Werk auch nicht auf eine Weise, die im Zeitpunkt des Vertragsschlusses mit dem Urheber noch unbekannt war (vgl. 32c UrhG). Z. B. war das Streaming von Musik oder Videos Anfang der 2000er Jahre eine damals noch unbekannte Nutzungsart.</ul> <b><br>\n\nGegenbeispiele: <br>\n<br></b><ul><li>\nEine bestimmte Fotografie oder ein Text erweisen sich überraschend im Nachhinein als „Bestseller“, sodass die ursprünglich vereinbarte Vergütung nicht mehr angemessen ist (vgl. § 32a UrhG). <br><li>\nEin Unternehmensjingle wurde im „rein analogen Zeitalter“ für Ihr Unternehmen erstellt und wird nun gestreamt (vgl. § 32c UrhG).",
            },

            {
              type: "html",
              name: "moreInfo2",
              visibleIf: "{relationInfoRevenue} = 'yManyCreatorsInvolved'",
              html: "<br><b>Beispiel (aus dem Journalismus):<br><br></b>Nutzung einer Vielzahl von urheberrechtlich geschützten Beiträgen in einer Nachrichtensendung",
            },
            {
              type: "html",
              name: "moreInfo3",
              visibleIf:
                "{relationInfoRevenue} = 'yCreatorsReceiveInfoRegularly'",
              html: "<br><b>Beispiele: <br><br></b><ul><li>Verkaufsabrechnungen für Autoren<br><li>Auskünfte von Verwertungsgesellschaften an ihre Mitglieder (z. B. GEMA)",
            },
          ],
          visibleIf:
            "{subLicensingYN} = 'yes' or {definingWorksYN} = 'veryDefining' or {purchasedWorksNowBestsellerYN} = true",
        },
        {
          type: "radiogroup",
          name: "notificationOfBestsellerSituationYN",
          visibleIf: "{definingWorksYN} = 'No'",
          title:
            "Hat der Urheber Ihnen oder Ihrem Unternehmen klare Anhaltspunkte dafür dargelegt, dass eine sogenannte „Bestsellersituation“ eingetreten ist und er deshalb die Auskunft von Ihnen benötigt (vgl. § 32a UrhG)?",
          choices: [
            {
              value: "true",
              text: "Ja",
            },
            {
              value: "false",
              text: "Nein",
            },
          ],
        },
        {
          type: "panel",
          name: "panelnotificationOfBestsellerSituationYNExample",
          elements: [
            {
              type: "html",
              name: "notificationOfBestsellerSituationYNExample",
              visibleIf: "{definingWorksYN} = 'No'",
              html: "<br><b>Beispiel:<br><br></b>Eine bestimmte Fotografie oder ein Text erweist sich überraschend im Nachhinein als Bestseller, sodass die ursprünglich vereinbarte Vergütung nicht mehr angemessen ist.",
            },
          ],
          visibleIf: "{definingWorksYN} = 'No'",
        },
        {
          type: "radiogroup",
          name: "breachSecrecyYN",
          visibleIf: "{relationInfoRevenue} = 'nothingApplies'",
          title:
            "Würde die Auskunft gegenüber dem Urheber berechtigte Geheimhaltungsinteressen verletzen?",
          choices: [
            {
              value: "yes",
              text: "Ja",
            },
            {
              value: "no",
              text: "Nein",
            },
            {
              value: "notSure",
              text: "Weiß ich nicht, da bin ich mir unsicher.",
            },
          ],
        },
      ],
    },
    {
      name: "resultNoObligationButInfoAsPerContract",
      elements: [
        {
          type: "html",
          name: "noObligationButInfoAsPerContract",
          visibleIf: "{tarifvertragYN} = true",
          html: "<div style='font-size: 14pt'>Es besteht zwar keine gesetzliche Auskunftspflicht nach § 32d UrhG. Ihr Unternehmen müsste dem Urheber aber entsprechend den Regelungen in dem Tarifvertrag Auskunft erteilen.</div>",
        },
        {
          type: "html",
          name: "noObligation",
          visibleIf:
            "{typeOfWork} = 'software' or {typeOfWork} = 'iirrelevantWorks' or {bestsellerYN} = false or {worksUsedFromCreator} = 'software' or {workOrigin} = 'trainees' or {purchasedWorksNowBestsellerYN} = false",
          html: "<div style='font-size: 14pt'>Ihr Unternehmen ist <u style='font-size: 14pt'>nicht</u> von der gesetzlichen Auskunftspflicht (§ 32d UrhG) betroffen.</div>",
        },
        {
          type: "html",
          name: "noObligationButSpecificInfoMutualRemuneration",
          visibleIf:
            "{mutualRemunerationTransparencyYN} = true or {mutualRemunerationAnnualInformationYN} = true",
          html: "<div style='font-size: 14pt'>Es besteht zwar keine gesetzliche Auskunftspflicht nach § 32d UrhG. Ihr Unternehmen müsste dem Urheber aber entsprechend den Regelungen in der Gemeinsamen Vergütungsregel Auskunft erteilen.</div>",
        },
        {
          type: "html",
          name: "noObligationButSpecificInfo",
          visibleIf:
            "{bestsellerYN} = true or {notificationOfBestsellerSituationYN} = true",
          html: "<div style='font-size: 14pt'>Es besteht zwar keine gesetzliche, generelle Auskunftspflicht nach § 32d UrhG. Ihr Unternehmen müsste dem Urheber dann aber eine spezifische Auskunft erteilen.</div>",
        },
        {
          type: "html",
          name: "improbableObligation",
          visibleIf:
            "{typeOfWork} = 'copyrightedWorksByAccident' or {worksUsedFromCreator} = 'iirrelevantWorks' or {relationInfoRevenue} = 'yNoLateRemunerationClaim' or {notificationOfBestsellerSituationYN} = false",
          html: "<div style='font-size: 14pt'>Es ist unwahrscheinlich, dass Ihr Unternehmen von der gesetzlichen Auskunftspflicht (§ 32d UrhG) betroffen ist.</div>",
        },
        {
          type: "html",
          name: "checkIndividualCase",
          visibleIf:
            "{relationInfoRevenue} = 'yManyCreatorsInvolved' or {relationInfoRevenue} = 'yCreatorsReceiveInfoRegularly' or {breachSecrecyYN} = 'yes' or {breachSecrecyYN} = 'notSure' or {subLicensingYN} = 'notSure' or {definingWorksYN} = 'notSure' or {relationInfoRevenue} = 'notSure'",
          html: "<div style='font-size: 14pt'>Ob Ihr Unternehmen von der gesetzlichen Auskunftspflicht (§ 32d UrhG) betroffen ist, sollte im Einzelfall geprüft werden. Beachten Sie, dass nach dem Willen des Gesetzgebers die Rechte des Urhebers geschützt werden sollen und deshalb gesetzliche Ausnahmen von der generellen Auskunftspflicht eng interpretiert werden. Das Unternehmen müsste im Streitfall darlegen und beweisen, dass solche Umstände vorliegen.</div>",
        },
        {
          type: "html",
          name: "improbableSectorDependant",
          visibleIf: "{breachSecrecyYN} = 'no'",
          html: "<div style='font-size: 14pt'>Es ist wahrscheinlich, dass Ihr Unternehmen von der Auskunftspflicht (§ 32d UrhG) betroffen ist. Der Umfang der zu erteilenden Auskünfte ist allerdings sehr branchenabhängig. Bei Fragen kontaktieren Sie uns gerne.</div>",
        },
        // {
        //   type: "panel",
        //   name: "panelContacts",
        //   elements: [
        //     {
        //       type: "expression",
        //       name: "contactOC",
        //       title:
        //         "Haben Sie weitere Fragen zur Urheberauskunftspflicht? Dann melden Sie sich gerne bei uns.",
        //     },
        //     {
        //       type: "html",
        //       name: "contactMS",
        //       html: '<div class="rowContacts">\n  <div class="columnContacts">\n <img alt="imgMS" src="https://oc-static.poweredbyproctors.co.uk/public/images/wp/20/10/27/martin.soppe_.jpg?VersionId=oikRdaZ0frxNq8Wj.KvULCm6paf5GJdu"\n         width="200" height="200">\n\n<div>\n <h5 class="contacts">Dr. Martin Soppe</h5>\n<h6>Rechtsanwalt/Partner</h6>\n</div>\n<a class="contactLink" href="mailto:martin.soppe@osborneclarke.com">E-Mail an Martin senden</a></p></div>\n\n\n\n\n  <div class="columnContacts">\n  <img alt="imgLH" src="https://oc-static.poweredbyproctors.co.uk/public/images/22/09/27/louisa.hartig.jpg?VersionId=En11Ry9PP2xYiuXhUSLyL4P4lMQUESzC"\n         width="200" height="200">\n<div class="contacts">\n<h5>Louisa Hartig</h5>\n<h6>Rechtsanwältin/Associate</h6>\n</div>\n<a class="contactLink" href="mailto:louisa.hartig@osborneclarke.com">E-Mail an Louisa senden</a></p>\n</div>\n\n\n\n  <div class="columnContacts">\n     <img alt="imgCF" src="https://oc-static.poweredbyproctors.co.uk/public/images/22/01/03/christoph.fuchs_.jpg?VersionId=CEjvu4.6gK63OANcsTMGv3AvJ4DZtm3r"\n         width="200" height="200">\n\n<div class="contacts">\n  <h5>Christoph Fuchs</h5>\n<h6>Rechtsanwalt/Associate</h6>\n</div>\n<a class="contactLink" href="mailto:christoph.fuchs@osborneclarke.com">Email an Christoph senden</a>\n\n\n</div></div>',
        //     },
        //     // {
        //     //   type: "html",
        //     //   name: "contactMS",
        //     //   html: '<img alt="imgMS" src="https://oc-static.poweredbyproctors.co.uk/public/images/wp/20/10/27/martin.soppe_.jpg?VersionId=oikRdaZ0frxNq8Wj.KvULCm6paf5GJdu"\n         width=200" height="200">\n\n<br><p class="contacts">Martin Soppe<br>\nRechtsanwalt/Partner</p>\n\n<a class="contactLink"\n href="mailto:martin.soppe@osborneclarke.com?subject=Anfrage zur Urheberauskunftspflicht">E-Mail an Martin senden</p>\n',
        //     // },
        //     // {
        //     //   type: "html",
        //     //   name: "contactLH",
        //     //   //startWithNewLine: false,
        //     //   html: ' <img alt="imgLH" src="https://oc-static.poweredbyproctors.co.uk/public/images/22/09/27/louisa.hartig.jpg?VersionId=En11Ry9PP2xYiuXhUSLyL4P4lMQUESzC"\n         width=200" height="200">\n\n<br><p class="contacts">Louisa Hartig<br>Rechtsanwältin/Associate</p><a class="contactLink"\n href="mailto:louisa.hartig@osborneclarke.com?subject=Anfrage zur Urheberauskunftspflicht"">E-Mail an Louisa senden</p>',
        //     // },
        //     // {
        //     //   type: "html",
        //     //   name: "contactCF",
        //     //   //startWithNewLine: false,
        //     //   html: '  <img alt="imgCF" src="https://oc-static.poweredbyproctors.co.uk/public/images/22/01/03/christoph.fuchs_.jpg?VersionId=CEjvu4.6gK63OANcsTMGv3AvJ4DZtm3r"\n         width=200" height="200">\n\n<br><p class="contacts">Christoph Fuchs<br>\nRechtsanwalt/Associate</p><a class="contactLink"\n href="mailto:christoph.fuchs@osborneclarke.com?subject=Anfrage zur Urheberauskunftspflicht"">Email an Christoph senden</p>',
        //     // },
        //   ],
        //   maxWidth: "fit-content",
        // },
      ],
      //title: "<div style='font-size: 14pt'; 'color:#ffffff'>Ergebnis</div>",
      title: "<div class='resultHeading'>Ergebnis</div>",
    },
  ],
  showQuestionNumbers: "off",
  clearInvisibleValues: "onHidden",
  startSurveyText: "Anfangen",
  pagePrevText: "Zurück",
  pageNextText: "Weiter",
  completeText: "Neu starten",
  previewText: "Vorschau",
  editText: "Bearbeiten",
  firstPageIsStarted: true,
  focusFirstQuestionAutomatic: false,
};
