import React from "react";

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./CardsElements";

const Services = (content) => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>{content.heading}</ServicesH1>
      <ServicesWrapper>
        {content.cards.map((singleCard) => {
          return (
            <ServicesCard>
              <ServicesIcon src={singleCard.image} />
              <ServicesH2>{singleCard.heading}</ServicesH2>
              <ServicesP>{singleCard.text}</ServicesP>
              <ServicesP>{singleCard.email}</ServicesP>
            </ServicesCard>
          );
        })}
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
